<template>
  <div>
    <v-row justify="center">
      <v-col
        cols="12"
        xl="8"
        sm="10"
        md="10"
      >

        <base-card>
          <v-card-text>
            <v-row>
              <v-col id="button">
                <v-btn
                  small
                  v-bind:color="btnNm === 'month' ? 'danger' : 'default'"
                  class="px-5 mr-5"
                  elevation="2"
                  style="margin-right:3px !important; "
                  @click="selectDate('month')"
                >
                  당월
                </v-btn>


                <v-btn
                  small
                  v-bind:color="btnNm === '1month' ? 'danger' : 'default'"
                  class="px-5 mr-5"
                  elevation="2"
                  style="margin-right:3px !important;"
                  @click="selectDate('1month')"

                >
                  전월
                </v-btn>
                <v-btn
                  small
                  v-bind:color="btnNm === '1day' ? 'danger' : 'default'"
                  class="px-5 mr-5"
                  elevation="2"
                  style="margin-right:3px !important;"
                  @click="selectDate('1day')"

                >
                  1일
                </v-btn>
                <v-btn
                  small
                  v-bind:color="btnNm === 'free' ? 'danger' : 'default'"
                  class="px-5 mr-5"
                  elevation="2"
                  style="margin-right:3px !important;"
                  @click="selectDate('free')"

                >
                  직접설정
                </v-btn>
              </v-col>
            </v-row>
            <div id="search_date">
              <v-menu
                ref="menuTwo"
                v-model="menuTwo"
                class="text-overline text-lg-subtitle-1"
                :return-value.sync="dates"
                transition="scale-transition"
                :close-on-content-click="false"
              >
                <template v-slot:activator="{on, attrs}">
                  <v-combobox
                    v-model="dateRangeText"
                    prepend-icon="mdi-calendar"
                    readonly
                    dense
                    v-bind="attrs"
                    class="text-overline text-lg-subtitle-1 pa-0"
                    outlined
                    @click="selectDate('free')"
                    v-on="on"
                  />
                </template>

                <v-container class="text-center">
                  <v-card>
                    <v-card-text class="align-center">
                      <v-date-picker
                        v-model="dates"
                        range
                        no-title
                        scrollable
                        locale="kr"
                      >
                        <v-spacer/>
                        <v-btn
                          text
                          color="primary"
                          @click="menuTwo = false"
                        >
                          취소
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="datesSave(dates)"
                        >
                          확인
                        </v-btn>
                      </v-date-picker>
                    </v-card-text>
                  </v-card>
                </v-container>
              </v-menu>
            </div>
          </v-card-text>
          <v-card-actions class="mt-n5">
            <v-btn
              color="warning"
              class="rounded-pill mr-5"
              depressed
              block
              @click="searchEvent">조회</v-btn>
          </v-card-actions>
        </base-card>
      </v-col>
      <!-- 지사 수익 -->
      <v-col
        cols="12"
        xl="8"
        sm="10"
        md="10"
        v-if=" loggedInUser.dealerKind && loggedInUser.dealerKind == 33"
      >
        <base-card :loading="loading">
          <v-list
            dense
            class="transparent"
          >
            <router-link :to='"/app/pages/salesRevenue?type=a&start_date="+dates[0]+"&end_date="+dates[1]'>
              <v-list-item>
                <v-list-item-subtitle>
                  <v-icon
                    x-small
                    color="red"
                    class="mx-1"
                  >
                    mdi-circle
                  </v-icon>
                  가맹점 매출 수익
                </v-list-item-subtitle>
                <v-list-item-title class="text-right">
                  {{ getJoinSalesSum }} 원
                </v-list-item-title>
              </v-list-item>
            </router-link>

            <router-link :to='"/app/pages/salesRevenue?type=b&start_date="+dates[0]+"&end_date="+dates[1]'>
              <v-list-item>
                <v-list-item-subtitle>
                  <v-icon
                    x-small
                    color="primary"
                    class="mx-1"
                  >
                    mdi-circle
                  </v-icon>
                  하위 대리점 매출 수익
                </v-list-item-subtitle>
                <v-list-item-title class="text-right">
                  {{ getDownSalesSum }} 원
                </v-list-item-title>
              </v-list-item>
            </router-link>

            <router-link :to='"/app/pages/salesRevenue?type=e&start_date="+dates[0]+"&end_date="+dates[1]'>
              <v-list-item>
                <v-list-item-subtitle>
                  <v-icon
                    x-small
                    color="primary"
                    class="mx-1"
                  >
                    mdi-circle
                  </v-icon>
                  모집 대리점 매출 수익
                </v-list-item-subtitle>
                <v-list-item-title class="text-right">
                  {{ getSosokSalesSum }} 원
                </v-list-item-title>
              </v-list-item>
            </router-link>

            <router-link :to='"/app/pages/salesRevenue?type=c&start_date="+dates[0]+"&end_date="+dates[1]'>
              <v-list-item>
                <v-list-item-subtitle>
                  <v-icon
                    x-small
                    color="warning"
                    class="mx-1"
                  >
                    mdi-circle
                  </v-icon>
                  가맹비 수익
                </v-list-item-subtitle>
                <v-list-item-title class="text-right">
                  {{ getJoinSum }} 원
                </v-list-item-title>
              </v-list-item>
            </router-link>

            <router-link :to='"/app/pages/salesRevenue?type=d&start_date="+dates[0]+"&end_date="+dates[1]'>
              <v-list-item>
                <v-list-item-subtitle>
                  <v-icon
                    x-small
                    color="indigo"
                    class="mx-1"
                  >
                    mdi-circle
                  </v-icon>
                  추천지사 수익
                </v-list-item-subtitle>
                <v-list-item-title class="text-right">
                  {{ getRecommendSum }} 원
                </v-list-item-title>
              </v-list-item>
            </router-link>
          </v-list>
          <v-divider/>
          <v-list
            color="green accent-5"
            dark
          >
            <v-list-item>
              <v-list-item-title>
                <v-icon
                  x-large
                  color="yellow"
                  class="mx-2"
                >
                  mdi-circle-small
                </v-icon>
                지급금액(예상)
              </v-list-item-title>
              <v-list-item-title class="text-right">
                {{ getBenefitSum }} 원
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </base-card>
      </v-col>


      <!-- 대리점 수익 -->
      <v-col
        cols="12"
        xl="8"
        sm="10"
        md="10"

        v-if=" loggedInUser.dealerKind && loggedInUser.dealerKind == 34"
      >
        <base-card :loading="loading">
          <v-list
            dense
            class="transparent"
          >

            <router-link :to='"/app/pages/salesRevenue?type=a&start_date="+dates[0]+"&end_date="+dates[1]'>
              <v-list-item>
                <v-list-item-subtitle>
                  <v-icon
                    x-small
                    color="red"
                    class="mx-1"
                  >
                    mdi-circle
                  </v-icon>
                  가맹점 매출 수익
                </v-list-item-subtitle>
                <v-list-item-title class="text-right">
                  {{ getJoinSalesSum }} 원
                </v-list-item-title>
              </v-list-item>
            </router-link>

            <router-link :to='"/app/pages/salesRevenue?type=e&start_date="+dates[0]+"&end_date="+dates[1]'>
              <v-list-item>
                <v-list-item-subtitle>
                  <v-icon
                    x-small
                    color="primary"
                    class="mx-1"
                  >
                    mdi-circle
                  </v-icon>
                  모집대리점 매출 수익
                </v-list-item-subtitle>
                <v-list-item-title class="text-right">
                  {{ getMojibSalesSum }} 원
                </v-list-item-title>
              </v-list-item>
            </router-link>

            <router-link :to='"/app/pages/salesRevenue?type=c&start_date="+dates[0]+"&end_date="+dates[1]'>
              <v-list-item>
                <v-list-item-subtitle>
                  <v-icon
                    x-small
                    color="warning"
                    class="mx-1"
                  >
                    mdi-circle
                  </v-icon>
                  가맹비 수익
                </v-list-item-subtitle>
                <v-list-item-title class="text-right">
                  {{ getJoinSum }} 원
                </v-list-item-title>
              </v-list-item>
            </router-link>
          </v-list>
          <v-divider/>
          <v-list
            color="indigo accent-4"
            dark
          >
            <v-list-item>
              <v-list-item-title>
                <v-icon
                  x-large
                  color="red"
                  class="mx-2"
                >
                  mdi-circle-small
                </v-icon>
                지급금액(예상)
              </v-list-item-title>
              <v-list-item-title class="text-right">
                {{ getBenefitSum }} 원
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </base-card>

      </v-col>




      <v-col
        cols="12"
        xl="8"
        sm="10"
        md="10"
        style="display: none"
      >
        <base-card  :loading="loading">
          <div
            color=""
            class="shadow-none"
          >
            <div
              class="d-flex justify-space-between flex-wrap align-center"
            >
              <v-card-title>
                <v-icon
                  x-small
                  color="primary"
                  class="mx-1"
                >
                  mdi-circle
                </v-icon>
                {{ gubunTitle }}
              </v-card-title>
              <div class="pa-2">
                <v-menu offset-y>
                  <template v-slot:activator="{on, attrs}">
                    <v-btn
                      color=""
                      v-bind="attrs"
                      class="shadow-none"
                      v-on="on"
                    >
                      {{ gubunTitle }}
                      <v-icon>mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(item, index) in menuList"
                      :key="index"
                      @click="agencyChange(item)"
                    >
                      <v-list-item-title>
                        {{
                          item
                        }}
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </div>
          </div>
        </base-card>
      </v-col>
      <v-col
        cols="12"
        xl="8"
        sm="10"
        md="10"
        class="text-center red--text">
        <b>&#8251;위 데이터는 조회 시점의 데이터로 추후 결제 취소, 환불 등에 의해 정산서와 상이 할 수 있습니다.</b>
      </v-col>
      <v-col
        cols="12"
        xl="8"
        sm="10"
        md="10"
      >
        <div class="d-flex align-center mb-4">
          <v-icon
            small
            color="primary"
            class="mr-3"
          >
            mdi-circle
          </v-icon>
          <h5 class="mb-0 mr-0 font-weight-bold">
            {{ gubunTitle }}
          </h5>
        </div>

        <base-card  :loading="loading">
          <v-card-text>
            <div
              class="text-center"
              v-if="tableContentList.length === 0"
            >
              조회된 정보가 없습니다.
            </div>

            <v-simple-table
              fixed-header
              v-if="tableContentList.length > 0"
            >
              <thead>
              <tr>
                <th
                  v-for="(header , index) in tableHeaderList"
                  :key="index"
                  class="text-center"
                  v-bind:style="header == '순번' ? 'width:70px;' : header == '수수료수익' ? 'width:110px;' : header == '가맹점' ? 'width:95px;' : ''"
                >
                  {{ header }}
                </th>
              </tr>
              </thead>
              <tbody>
              <tr
                v-for="(contentArr , index) in tableContentList"
                :key="'first'+index"
                class="text-center"
              >
                <td v-for="(content , index) in contentArr" :key="index" >
                  {{ content }}
                </td>

              </tr>
              </tbody>
            </v-simple-table>
            <v-pagination
              v-if="tableContentList.length > 0"
              v-model="currentPage"
              class="my-4"
              :length="pageCount"
              :total-visible="10"
              @next="pageHandler()"
              @previous = "pageHandler()"
              @input = "pageHandler()"
            />
          </v-card-text>
        </base-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
  import {mapActions, mapGetters, mapMutations} from "vuex";
  import {currency, currentDay, dataType} from "@/filter/filter";

  export default {
    name: 'SalesRevenue',
    created() {
      this.setSelectedMenu('수익현황')
      if(this.loggedInUser.dealerKind === 33){
        this.gubunTitle = '가맹점 매출 수익'
        this.menuList = [
          '가맹점 매출 수익',
          '하위대리점 매출 수익',
          '가맹비 수익',
          '추천 지사 수익',
        ]
      }
      if(this.loggedInUser.dealerKind === 34){
        this.gubunTitle = '가맹점 매출 수익'
        this.menuList = [
          '가맹점 매출 수익',
          '모집대리점 매출 수익',
          '가맹비 수익',
        ]
      }


    },
    mounted() {

      const search_date_document = document.getElementById("search_date");
      search_date_document.addEventListener("click", (e) => {
        this.menuTwo = true;
      })

      const start_date = this.$route.query.start_date;
      const end_date = this.$route.query.end_date;

      if(start_date && end_date){
        this.dates = [];
        this.dates.push(start_date);
        this.dates.push(end_date);
      }

      this.initData()


      this.fetchMerchantIncomeSum(this.payLoad);
      switch(this.$route.query.type) {
        case "a":
          this.gubunTitle = "가맹점 매출 수익";
          break;
        case "b":
          this.gubunTitle = "하위대리점 매출 수익";
          break;
        case "c":
          this.gubunTitle = "가맹비 수익";
          break;
        case "d":
          this.gubunTitle = "추천 지사 수익";
          break;
        case "e":
          this.gubunTitle = "모집대리점 매출 수익";
          break;
      }
      this.agencyChange( this.gubunTitle)
    },
    data: () => ({
      btnNm: 'month',
      loading: false,
      currentPage: 1,
      pageCount: 10,
      pageSize: 10,
      dates: [],
      menuTwo: false,
      gubunTitle: '가맹점 매출 수익',
      payLoad: {
        startDt: '',
        endDt: '',
        startNo: 1,
        endNo: 5,
        title: null,
        content: null,
        type: null,
        dealerKind: 0,
        userId: '',
      },
      menuList: [
        '가맹점 매출 수익',
        '모집대리점 매출 수익',
        '가맹비 수익',
      ],
      agencyMenuHeader1: [
        '순번', '가맹점', '결제금액', '수수료수익'
      ],
      agencyMenuHeader2: [
        '순번', '대리점', '매출금액', '수수료수익'
      ],
      agencyMenuHeader3: [
        '순번', '대리점', '등록일', '수수료수익'
      ],
      agency2MenuHeader1: [
        '순번', '대리점', '매출금액', '수수료수익'
      ],
      agency2MenuHeader2: [
        '순번', '대리점', '등록일', '가맹비수익'
      ],
      agency2MenuHeader3: [
        '순번', '지사', '구분', '매출금액', '수익'
      ],
    }),
    computed: {
      ...mapGetters(
        [
          'getJoinSalesSum',
          'getMojibSalesSum',
          'getSosokSalesSum',
          'getJoinSum',
          'getRecommendSum',
          'getBenefitSum',
          'getJoinSalesList',
          'getJoinSalesCount',
          'getMojibSalesList',
          'getMojibSalesCount',
          'getSosokSalesList',
          'getSosokSalesCount',
          'getDownSalesSum',
          'getRecommendList',
          'getRecommendCount',
          'getJoinAmountList',
          'getJoinAmountCount',
          'loggedInUser',
          'loading',
        ]
      ),

      dateRangeText() {
        return this.dates.join(' ~ ')
      },
      // 대리점인경우 메뉴선택에 따른 헤더값 변경
      tableHeaderList() {
        if (this.gubunTitle === '가맹점 매출 수익') return this.agencyMenuHeader1
        if (this.gubunTitle === '모집대리점 매출 수익') return this.agencyMenuHeader2
        if (this.gubunTitle === '가맹비 수익' && this.loggedInUser.dealerKind===34 ) return this.agencyMenuHeader3

        if (this.gubunTitle === '하위대리점 매출 수익') return this.agency2MenuHeader1
        if (this.gubunTitle === '가맹비 수익' && this.loggedInUser.dealerKind===33 ) return this.agency2MenuHeader2
        if (this.gubunTitle === '추천 지사 수익') return this.agency2MenuHeader3

        return this.agencyMenuHeader1
      },
      // 대리점인 경유 메뉴선택에 따른 내용선택
      tableContentList() {
        this.payLoad.startNo = 1
        this.payLoad.endNo = 10
        let targetArr = []
        let tempArr = []
        if (this.gubunTitle === '하위대리점 매출 수익') {
          targetArr = this.getSosokSalesList.map(item => {
            return [item.no, item.memberName, currency(item.amount) + '원', currency(item.feeAmount)+ '원']
          })
        } else if (this.gubunTitle === '추천 지사 수익') {
          targetArr = this.getRecommendList.map(item => {
            return [item.no, item.memberName, '매출' ,currency(item.amount) + '원', currency(item.feeAmount)+ '원']
          })
        }else if (this.gubunTitle === '가맹점 매출 수익') {
          if(this.loggedInUser.dealerKind===33) {
            targetArr = this.getJoinSalesList.map(item => {
              return [item.no, item.memberName,currency(item.amount) + '원', currency(item.feeAmount)+ '원']
            })
          }
          if(this.loggedInUser.dealerKind===34) {
            targetArr = this.getJoinSalesList.map(item => {
              return [item.no, item.memberName, currency(item.amount) + '원', currency(item.feeAmount) + '원']
            })
          }
        }else if (this.gubunTitle === '모집대리점 매출 수익') {
          targetArr = this.getMojibSalesList.map(item => {
            return [item.no, item.memberName,currency(item.amount) + '원', currency(item.feeAmount)+ '원']
          })

        } else if (this.gubunTitle === '가맹비 수익') {
          if(this.loggedInUser.dealerKind===33) {
            targetArr = this.getJoinAmountList.map(item =>{
              return [item.no, item.cmpnm,  item.createDt ,  currency(item.amount) + '원']
            });
          }
          if(this.loggedInUser.dealerKind===34) {
            targetArr = this.getJoinAmountList.map(item =>{
              return [item.no, item.cmpnm,  item.createDt ,  currency(item.amount) + '원']
            });
          }

        } else {
          targetArr = []
          this.pageCount = 1;
        }
        targetArr.map(item => {
          const firstArr = []
          // eslint-disable-next-line no-unused-vars
          for (const [key, value] of Object.entries(item)) {
            firstArr.push(value)
          }
          tempArr.push(firstArr)
        })
        return tempArr;
      },
    },
    methods: {
      searchEvent(){
        this.initData()
        this.fetchMerchantIncomeSum(this.payLoad)
        this.agencyChange(this.gubunTitle)
      },
      ...mapActions([
        'fetchMerchantIncomeSum',
        'fetchJoinSalesList',
        'fetchJoinAmountList',
        'fetchMojibSalesList',
        'fetchSosokSalesList',
        'fetchRecommendList',
      ]),
      ...mapMutations([
        'setJoinSalesList',
        'setJoinSalesCount',
        'setMojibSalesList',
        'setMojibSalesCount',
        'setSosokSalesList',
        'setSosokSalesCount',
        'setDownSalesSum',
        'setRecommendList',
        'setRecommendCount',
        'setJoinAmountList',
        'setJoinAmountCount',
        'setSelectedMenu',
        'toggleThemeLoadingState'
      ]),
      pageHandler () {
        this.payLoad.startNo = (this.currentPage - 1) * this.pageSize + 1
        this.payLoad.endNo = this.pageSize * this.currentPage
        this.agencyChange( this.gubunTitle )
      },
      // 검색파라미터 초기화
      initData() {
        if (this.dates.length === 0) {
          this.dates = [currentDay(true), currentDay()]
        }
        this.payLoad = {
          startDt: this.dates[0],
          endDt: this.dates[1],
          startNo: 1,
          endNo: 10,
          title: null,
          content: null,
          type: null,
          dealerKind: this.loggedInUser.dealerKind,
          userId: this.loggedInUser.dealerId,
        }
      },
      // 목록 데이터 모두 초기화 제거 -- 잔류 데이터로 혼란있었음
      initListData() {
        this.pageCount = 10


        this.setJoinSalesList([])
        this.setJoinSalesCount(0)
        this.setMojibSalesList([])
        this.setMojibSalesCount(0)
        this.setSosokSalesList([])
        this.setSosokSalesCount(0)
        this.setRecommendList([])
        this.setRecommendCount(0)
        this.setJoinAmountList([])
        this.setJoinAmountCount(0)
        // this.setDownSalesSum(0)


      },
      // 대리점
      agencyChange(val) {
        this.gubunTitle = val
        this.initListData()
        this.loading=true
        if (val === '가맹점 매출 수익' || val == 'a') {
          this.fetchJoinSalesList(this.payLoad)
            .then(_ => {
              this.pageCount = Math.ceil(this.getJoinSalesCount / this.pageCount)
              this.loading=false
            })
        } else if (val === '가맹비 수익' || val == 'c') {
          this.fetchJoinAmountList(this.payLoad).then(_ => {
            this.pageCount = Math.ceil(this.getJoinAmountCount / this.pageCount)
            this.loading=false
          })
        } else if (val === '모집대리점 매출 수익') {
          /** 지사 OR 대리점 */
          this.fetchMojibSalesList(this.payLoad)
            .then(_ => {
              this.pageCount = Math.ceil(this.getMojibSalesCount / this.pageCount)
              this.loading=false
            })
        } else if (val === '하위대리점 매출 수익' || val == 'b') {
          this.fetchSosokSalesList(this.payLoad)
            .then( _ => {
              this.pageCount = Math.ceil(this.getSosokSalesCount / this.pageCount)
              this.loading=false
            })
        }
        else if (val === '추천 지사 수익' || val == 'd') {
          this.fetchRecommendList(this.payLoad)
            .then(_ => {
              this.pageCount = Math.ceil(this.getRecommendCount / this.pageCount)
              this.loading = false
            })
        }
      },

      selectDate(day) {

        let vPrevDate;
        let vNowDate = new Date().getFullYear()+"-"+this.pad(new Date().getMonth() + 1, 2)+"-"+this.pad(new Date().getDate(), 2);
        let vResult;

        let d = new Date();
        let dayOfMonth = d.getDate();


        switch (day) {
          case 'month':
            vPrevDate = d.getFullYear()+"-"+this.pad(d.getMonth() + 1,2)+"-"+"01";
            this.dates = [vPrevDate, dataType()]
            break
          case '1day':

            d.setDate(dayOfMonth - 1);
            // vPrevDate = d.getFullYear()+"-"+this.pad(d.getMonth() + 1,2)+"-"+this.pad(d.getDate(),2);
            this.dates = [dataType(), dataType()]
            break
          case '1month':
            d.setMonth(d.getMonth() - 1);
            vPrevDate = dataType(new Date(d.getFullYear(), d.getMonth(), 1));
            this.dates = [vPrevDate, dataType(new Date(d.getFullYear(), d.getMonth() + 1, 0))]
            break
          case 'free':
            this.dates = [dataType(90), dataType()]
            this.dates = ['', '']
            break
        }

        this.btnNm = day
      },
      pad(n, width) {
        n = n + '';
        return n.length >= width ? n : new Array(width - n.length + 1).join('0') + n;
      },
      datesSave(date) {
        date = date.sort();
        this.$refs.menuTwo.save(date);
      }

    },
  }
</script>

<style>
@media ( max-width: 500px ) {
  #button {
    display: flex;
    justify-content: center;
    margin: auto;
  }
}
</style>
